@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

:root {
    --primary-cyan: hsl(180, 66%, 49%);
    --primary-dark: hsl(257, 27%, 26%);
    --secondary-locor: hsl(0, 87%, 67%);
    --gray: hsl(0, 0%, 75%);
    --grayish: hsl(257, 7%, 63%);
    --dark-blue: hsl(255, 11%, 22%);
    --dark-violet: hsl(260, 8%, 14%);
    --white-color: hsl(0, 0%, 100%);

    --max_width: 1440px;
    --transition-3ms: .3s;
}

html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
}

/* Resets */
*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
p {
    margin-top: 0;
    vertical-align: text-top;
}

h1,
h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

ul,
li {
    list-style: none;
}

img,
picture,
svg {
    max-width: 100%;
    display: block;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background-color: rgb(235, 235, 235);
}

::-webkit-scrollbar-thumb {
    background: var(--primary-dark);
    border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
    background: hsl(258, 43%, 51%);
    border-radius: 50px;
}

#root {
    width: 100%;
}

/* Body */
body {
    font-family: "Poppins", sans-serif;
    text-rendering: optimizeSpeed;
    overflow-x: hidden;
  }


/* Navigation */
.nav_container {
    width: 100%;
    position: fixed;
    padding: 2rem 0;
    overflow: hidden;
    transition: var(--transition-3ms);
    background-color: transparent;
    z-index: 999;
    background-color: var(--white-color);
    transition: .3s ease;
}

.nav_container.scrolled {
    padding: 1rem 0;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: var(--max_width);
    margin-inline: auto;
    padding: 0 3rem;
}


.navigation {
    width: 100%;
    display: flex;    
    justify-content: space-between;
    align-items: center;
}

.nav_lists,
.nav_list-container,
.nav_list-container-login {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.nav_lists {
    justify-content: space-between;
}

.logo_container {
    margin-right: 1.5rem;
}

.nav_link {
    color: var(--grayish);
    font-weight: 600;
    font-size: 1.2rem;
    transition: .3s ease;
}

.nav_link:hover {
    color: var(--dark-violet);
}

.primary-btn,
.btn {
    transition: .3s ease;
}

.primary-btn:hover,
.btn:hover {
    background-color: hsl(180, 64%, 73%);
}

.primary-btn {
    color: var(--white-color);
    background-color: var(--primary-cyan);
    padding: .5rem 1.4rem;
    border-radius: 40px;
    font-size: 1.2rem;
    font-weight: 500;
    border: none;
    outline: none;
}

.btn {
    color: var(--white-color);
    background-color: var(--primary-cyan);
    padding: 1rem 2.5rem;
    border-radius: 40px;
    font-weight: 600;
    border: none;
    outline: none;
    cursor: pointer;
}

.submit_btn {
    width: 25%;
    min-width: 13rem;
    border-radius: 10px;
    font-size: 1.5rem;
}

.menu {
    display: none;
    cursor: pointer;
}

.menu__btn {
    display: flex;
    flex-direction: column;
    margin: 3px auto;
    width: 25px;
    height: 3px;
    background-color: var(--dark-violet);
    border-radius: 5px;
    transition: .4s ease;
}

@media screen and (max-width: 770px) {
    .nav {
        padding: 0 1rem;
    }

    .nav_lists {
        display: none;
    } 
    
    .menu {
        display: block;
    }
}

/* Hero content */
.main_container {
    padding: 0 3rem;
    max-width: var(--max_width);
    margin-inline: auto;
}

.hero_container {
    position: relative;
    padding-top: 10rem;
    display: flex;
}

.hero_section-left {
    max-width: 40rem;
}

.hero_image {
    position: absolute;
    top: 120px;
    right: -200px;
}

.hero_heading {
    font-size: 5rem;
    color: var(--dark-violet);
    line-height: 90px;
}

.hero_content {
    font-size: 1.3rem;
    width: 80%;
    color: var(--grayish);
    margin-top: .6rem;
    margin-bottom: 1.8rem;
}

@media screen and (max-width: 1130px) {
    .hero_heading {
        font-size: 3rem;
        max-width: 500px;
        line-height: initial;
    }

    .hero_content {
        max-width: 500px;
        width: 100%;
        padding: 0 1rem;
    } 

    .hero_image {
        max-width: 600px;
    }

    .hero_section-left {
        text-align: center;
    }
}

@media screen and (max-width: 850px) {

    .main_container {
        padding: 0 .5rem;
    }

    .hero_container {
        flex-direction: column-reverse;
        align-items: center;
        padding-top: 7rem;
        overflow-x: hidden;
    }

    .hero_image {
        position: initial;
        margin-right: -180px;
        max-width: 450px;
    }

    .hero_heading {
        margin: 2rem auto 1rem ;
        text-align: center;
    }

    .hero_content {
        margin: 0 auto;
        text-align: center;
        margin-bottom: 2rem;
    }

    .hero_image {
        top: initial;
        right: initial;
    }
}
/* Input field*/
.input_container {
    position: relative;
    margin-inline: auto;
    max-width: var(--max_width);
    margin-top: 12rem;
    width: 100%;
    padding: 3.5rem 4.5rem;
    border-radius: 15px;
    background-color: var(--primary-dark);
    background-image: url("../images/bg-shorten-desktop.svg");
    background-repeat: no-repeat;
    background-size: cover;
}

.form_container {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 2rem;
}

.input_container input {
    padding: 1.6rem 2.2rem;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: 1.4rem;
    font-weight: 600;
    color: var(--grayish);
}

.input_container input.input_error{
    outline: 4px solid var(--secondary-locor);
}

.input_container input.input_error::placeholder {
    color: var(--secondary-locor);
}

.input_error-message {
    display: none;
    position: absolute;
    left: 0;
    top: 90px;
    font-size: 1.2rem;
    color: var(--secondary-locor);
    font-style: italic;
}

.input_error-message.active {
    display: block;
}

.input_container input::placeholder {
    font-size: 1.4rem;
    color: var(--grayish);
    font-weight: 600;
}

@media screen and (max-width: 850px) {

    .input_container {
        padding: 1.5rem;
        margin-top: 6rem;
    }

    .form_container {
        flex-direction: column;
    }

    .form_container {
        gap: 1rem;
    }

    .input_container input {
        padding: 1rem 1.2rem;
        font-size: 1rem;
    }

    .input_error-message.active {
        position: initial;
        right: 50px;
    }

    .input_container input::placeholder {
        font-size: 1rem;
    }

    .submit_btn {
        width: 100%;
        padding: 1rem;
        font-size: 1rem;
    }
}

/* Testimonial */
.testimonial_container {
    margin-inline: auto; 
    background-color: rgb(235, 235, 235);
    margin-top: -6rem;
    padding-top: 7.5rem;
    padding-bottom: 15rem;
}

.testimonial_wrapper {
    max-width: var(--max_width);
    margin-inline: auto;  
    text-align: center;
    padding: 0 3rem;
  }

.testimonial_heading {
    color: var(--dark-violet);
    font-size: 2.8rem;
}

.testimonial_content {
    color: var(--grayish);
    max-width: 45%;
    margin-inline: auto;
    margin-top: 1rem;
    font-size: 1.4rem;
}

.tesimonials_cards-container {
    position: relative;
    display: flex;
    gap: 2rem;
    margin-top: 6rem;
}

.tesimonials_cards-container::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 50%;
    left: 50%;
    top: 160px;
    transform: translate(-50%,-50%);
    background-color: var(--primary-cyan);
}

.testimonial_cards {
    position: relative;
    display: flex;
    gap: 1.3rem;
    flex-direction: column;
    background-color: var(--white-color);
    padding: 4.5rem 2rem 3rem 2rem;
    height: 100%;
    text-align: left;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 6px;
}

.testimonial_cards:nth-child(2) {
    transform: translate(0, 50px);
}

.testimonial_cards:nth-child(3) {
    transform: translate(0, 100px);
}

.testimonial_cards::before {
    content: "";
    position: absolute;
    height: 80px;
    width: 80px;
    top: -40px;
    background-color: var(--primary-dark);
    border-radius: 50px;
}

.testimonial_cards-heading {
    font-size: 1.5rem;
    color: var(--primary-dark);
}

.testimonial_cards-content {
    color: var(--grayish);
}

.testimonial_cards-icons {
    position: absolute;
}

.testimonial_cards:nth-child(1) .testimonial_cards-icons{
    top: -22px;
    left: 51px;
}

.testimonial_cards:nth-child(2) .testimonial_cards-icons{
    top: -20px;
    left: 51px;
}

.testimonial_cards:nth-child(3) .testimonial_cards-icons{
    top: -22px;
    left: 47px;
}

@media screen and (max-width: 850px){
    .testimonial_container {
        padding-bottom: 12rem;
    }
    .testimonial_heading {
        font-size: 2.2rem;
    }

    .testimonial_content {
        margin-top: 2rem;
        padding: 0 1rem;
        max-width: 100%;
    }

    .tesimonials_cards-container {
        flex-direction: column;
    }

    .testimonial_cards {
        text-align: center;
    }

    .testimonial_cards::before,
    .testimonial_cards:nth-child(1) .testimonial_cards-icons,
    .testimonial_cards:nth-child(2) .testimonial_cards-icons,
    .testimonial_cards:nth-child(3) .testimonial_cards-icons {
        left: 50%;
        transform: translate(-50%, 0);
    }

    .tesimonials_cards-container::before {
        height: 60%;
        width: 9px;
        top: initial;
        bottom: -120px;
    }
}

/* Boost sectio */
.boost_section-container {
    text-align: center;
    background-color: var(--primary-dark);
    background-image: url("../images/bg-shorten-desktop.svg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    background-position: right bottom;
    padding: 4rem 0;
}

.boost_section-heading {
    font-size: 2.8rem;
    color: var(--white-color);
    margin-bottom: 2rem;
}

/* Footer section */
.footer {
    width: 100%;
    background-color: var(--dark-violet);
}

.footer_section-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: var(--max_width);
    padding: 5rem 3rem;
    margin-inline: auto;
    justify-content: space-between;
}

.footer_logo-container {
    padding-right: 10rem;
}

.footer_links-heading {
    font-size: 1.2rem;
    color: var(--white-color);
}

.links_container {
    margin-top: -5px;
}

.social_container {
    display: flex;
    gap: 1.6rem;
    cursor: pointer;
    transition: ease .3s;
}

.social_container img {
    transition: ease .3s;
}

.social_container img:hover {
    scale: 1.3;
}

.footer_links {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: .8rem;
}

.footer_link {
    cursor: pointer;
    transition: .3s ease;
    color: var(--grayish);
}

.footer_link:hover {
    color: var(--primary-cyan);
}

@media screen and (max-width: 920px) {
    .footer_logo-container {
        padding-right: initial;
    }

    .boost_section-heading {
        font-size: 1.7rem;
        padding: 0 1rem
    }

    .footer_section-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 2rem;
        padding: 4rem 2rem;
    }

    .footer_logo-container {
        margin-bottom: 2rem;
    }

    .social_container {
        margin-top: 2rem;
    }
}

/* Shorted links section */
.shortedlink_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: var(--max_width);
    margin-inline: auto;
    background-color: white;
    border-radius: 5px;
    text-align: left;
    padding: 1rem 2rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 6px;
}

.shorted_component-wrapper {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 5rem;
}

.shortedlink_wrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.copy_link-btn {
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 8px;
    padding: .6rem 1.8rem;
}

.copied_link-btn {
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 8px;
    padding: .6rem 1.8rem;
    background-color: var(--primary-dark);
}

.copied_link-btn:hover {
    background-color: hsl(259, 31%, 44%);
}

.original_link,
.shorted_link {
    font-size: 1.3rem;
    font-weight: 500;
    overflow: auto;
}

.original_link {
    color: var(--dark-blue)
}

.shorted_link {
    color: var(--primary-cyan)
}

.button_container {
    display: flex;
    gap: 1rem;
}

.fa-trash {
    font-size: 2rem;
    color: var(--primary-dark);
    transition: .3s ease;
    cursor: pointer;
}

.fa-trash:hover {
    scale: 1.2;
}

.delete_btn {
    border: none;
    outline: none;
    background: none;
}

@media screen and (max-width: 850px) {
    .testimonial_wrapper {
        padding: 0 .5rem;
    }

    .shorted_component-wrapper {
        margin-bottom: 3rem;
    }

    .shortedlink_container {
        flex-direction: column;
        padding: 1rem;
        gap: 1rem;
        align-items: initial;
    }

    .shortedlink_wrapper {
        flex-direction: column;
        align-items: initial;
    }

    .copy_link-btn,
    .copied_link-btn {
        width: 100%;
    }
}